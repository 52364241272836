/* eslint-disable multiline-ternary */
'use client'

import { forwardRef } from 'react'
import styles from './Button.module.css'
import TButtonProps from './Button.type'
import { buttonVariants } from './variants'
import Spinner from '../Spinner'

const Button = forwardRef<HTMLButtonElement, TButtonProps>(function Button(props, ref) {
  const {
    disabled,
    isLoading,
    IconLeft,
    IconRight,
    size = 'medium',
    variant = 'solid',
    children,
    className,
    ...rest
  } = props

  return (
    <button
      ref={ref}
      className={buttonVariants({ size, variant, className })}
      disabled={disabled}
      {...rest}
      data-variant={variant}
      data-size={size}
    >
      {isLoading ? (
        <Spinner color="tertiary" size="small" className="mr-2" />
      ) : IconLeft ? (
        <IconLeft className={styles.iconLeft} />
      ) : (
        <></>
      )}
      {!!children && <span>{children}</span>}
      {!!IconRight && <IconRight className={styles.iconRight} />}
    </button>
  )
})

export default Button
