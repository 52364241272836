import { cva } from 'class-variance-authority'
import styles from './Button.module.css'

export const buttonVariants = cva(styles.button, {
  variants: {
    variant: {
      solid: styles['button--variantSolid'],
      soft: styles['button--variantSoft'],
      outline1: styles['button--variantOutline1'],
      outline2: styles['button--variantOutline2'],
      transparent: styles['button--variantTransparent'],
      transparentAccent: styles['button--variantTransparentAccent'],
    },
    size: {
      small: styles['button--sizeSmall'],
      medium: styles['button--sizeMedium'],
      large: styles['button--sizeLarge'],
    },
  },
  defaultVariants: {
    variant: 'solid',
    size: 'medium',
  },
})
