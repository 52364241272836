'use client'
import { TSpinnerProps } from './Spinner.type'
import styles from './Spinner.module.css'
import { cx } from 'class-variance-authority'

function Spinner(props: TSpinnerProps) {
  const { size = 'medium', color = 'primary', weight, className, ...rest } = props
  return (
    <div {...rest} className={cx(styles.spinner, className)} data-size={size} data-color={color} data-weight={weight} />
  )
}

export default Spinner
